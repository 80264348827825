.order-item {
    display: grid;
    grid-template-columns: 50px 1fr 20px;
    gap: 12px;
    align-items: center;

    .select {
        position: relative;
        select {
            border-radius: 100px;
            padding: 4px 8px;
            width: 100%;
            color: var(--white);
            background: var(--primary);
            border: none;
            outline: none;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            -webkit-appearance: none;
            -moz-appearance: none;
            cursor: pointer;

            option {
                background-color: var(--white);
                color: var(--black);
            }
        }
        .dropdown {
            position: absolute;
            right: 8px;
            top:1px;
            pointer-events: none;
        }
    }

    .ticketName {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
        text-align: left;
    }
    .ticketName-light {
        font-size: 20px;
        line-height: 24px;
    }

    .trash {
        width: 20px;
        height: 20px;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

@media only screen and (max-width: 490px) {
    .order-item  {
        .select {
            .dropdown {
                top: 0px;
            }
        }
        .ticketName {
            font-size: 16px;
            line-height: 22px;
        }
        .ticketName-light {
            font-size: 16px;
            line-height: 22px;
        }
    }
}