.thank-you-container {
    // background-color: var(--background);
    display: flex;
    flex-direction: column;
    gap: 24px;
    display: '-webkit-box';

    .eventComing {
        padding-top: 20px;
        font-size: 22px;
        line-height: 28px;
        color: var(--text-title);
        text-align: center;
        text-transform: uppercase;
        white-space: pre-line;
    }

    .website { 
        color: var(--text-title);
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        text-decoration: none;
    }

    .thank-you {
        display: flex;
        flex-direction: column;
        gap: 8px;
        display: '-webkit-box';

        .retrieve-title {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            text-transform: uppercase;
            color: var(--text-title);
        }
        .retrieve-title-light {
            font-weight: 400;
            font-size: 22px;
            line-height: 26px;
        }
        .inCHiApp-title {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: var(--text-download-title);
            font-family: var(--font-secondary);
            text-transform: uppercase;
        }
        .inCHiApp-title-light {
            font-weight: 400;
            font-size: 22px;
            line-height: 26px;
        }
    }

   .download {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        display: '-webkit-box';

        img {
            transition: all 0.2s;
            cursor: pointer;
            &:hover {
                opacity: 0.8;
            }
            height: 52px
        }
   }

   .contract {
        display: flex;
        flex-direction: column;
        gap: 8px;
        display: '-webkit-box';

        .important {
            font-weight: 400;
            font-size: 24px;
            line-height: 26px;
            color: var(--text-title);
            text-align: center;
            text-transform: uppercase;
        }

        .content-contract {
            display: flex;
            flex-direction: column;
            gap: 4px;
            display: '-webkit-box';

            .text-contract {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                text-transform: uppercase;
                color: var(--text-title);
            }

            .email-contract {
                color: var(--text-title);
                font-size: 16px;
                line-height: 24px;
            }
        }
   }
}

@media only screen and (max-width: 480px) {
    .thank-you-container {
        gap: 16px;
        .eventComing {
            font-size: 18px;
            line-height: 24px;
        }
        .website { 
            font-size: 14px;
            line-height: 18px;
        }
        .thank-you {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .retrieve-title {
                font-size: 18px;
                line-height: 24px;
            }
            .retrieve-title-light {
                font-size: 18px;
                line-height: 24px;
            }
            .inCHiApp-title {
                font-size: 18px;
                line-height: 24px;

            }
            .inCHiApp-title-light {          
                font-size: 18px;
                line-height: 24px;
            }
        }

       .download {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 8px;
            img {
                transition: all 0.2s;
                cursor: pointer;
                &:hover {
                    opacity: 0.8;
                }
                height: 52px
            }
       }

       .contract {
            .important {
                font-size: 20px;
                line-height: 26px;
            }
            .content-contract {
                .text-contract {
                    font-size: 12px;
                    line-height: 22px;
                }
                .email-contract {
                    font-size: 14px;
                    line-height: 22px;
                    color: var(--text-title);
                }
            }
       }
    }
}