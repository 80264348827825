.order-title {
    margin: 24px 0;
}

.order {
    padding: 16px;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    
    .list {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap:16px;


        .header {
            display: grid;
            grid-template-columns: 50px 1fr;
            gap: 12px;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            width: 100%;
            justify-items: flex-start;
        }
        .header-light {
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
        }
        .tickets {
            display: flex;
            flex-direction: column;
            gap:16px;
            width: 100%;
        }
    }

    .divider {
        width: 100%;
        height: 24px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .list-price {
        display: flex;
        flex-direction: column;
        gap:8px;
        padding-top: 24px;


        .item-price {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .label {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                font-family: var(--font-secondary);
                text-transform: uppercase;
            }

            .label-light {
                line-height: 24px;
            }

            .price {
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                text-transform: uppercase;
            }
            .price-light {
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
}

.wrapperTooltip {
    display: flex;
    align-items: center;
.tooltip {
    position: relative;
    display: inline-block;
    margin-left: 5px; 
    margin-top: 1px;
 }
  .iconInfo {
    width: 13px;
    height: 13px;
  }
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: black;
    color: #fff;
    border-radius: 8px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    left: 25px;
    top: -24px;
    font-size: 14px;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
}

@media only screen and (max-width: 490px) {
    .order-title {
        margin: 16px 0;
    }
    .order {
        padding: 12px;
        border-radius: 16px;
        .list {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            gap:16px;
            .tickets {
                gap:12px;
            }
        }
        .divider {
            height: 12px;
        }
        .list-price {
            gap:4px;
            padding-top: 12px;
            .item-price {
                .label {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                }
                .label-light {
                    line-height: 20px;
                }
                .price {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                }
                .price-light {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }
    .wrapperTooltip {
        .tooltip {
            margin-left: 4px;
          }
          .iconInfo {
            width: 11px;
            height: 11px;
          }
          .tooltip .tooltiptext {
            width: 150px;
            padding: 8px;
            position: absolute;
            z-index: 1;
            left: 18px;
            top: -12px;
            font-size: 10px;
          }
          
          .tooltip:hover .tooltiptext {
            visibility: visible;
          }
        }
}